import React, { Component } from 'react';
import '../App.css';

class DemonCoin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animationType: "bars",
      loveMatched: false,
      numbersCounter: 0,
    }

    this.numbers = [];

  }

  componentDidMount() {

    if(this.state.animationType === "bars") {
      this.setState({numbersCounter: 0});
      let timer = setInterval(()=>{
        this.barChange();
        this.setState({numbersCounter: this.state.numbersCounter += 1});
        if(this.state.numbersCounter === 15) {
          clearInterval(timer);
          this.setState({animationType: "numbers"})
          this.animationNumbers();
        }
      }, 1000)
    }
  
  }

  animationNumbers(){
    this.setState({numbersCounter: 0}, ()=>{
        let timer = setInterval(()=>{
        this.numbers = [];
        this.setState({numbersCounter: this.state.numbersCounter += 1});
        console.log(this.state.numbersCounter);
        if(this.state.numbersCounter === 15) {
          clearInterval(timer);
          this.setState({animationType: "bars"});
          this.animationBars();
        }
      }, 1000)
      });
  }

  animationBars(){
    this.setState({numbersCounter: 0});
      let timer = setInterval(()=>{
        this.barChange();
        this.setState({numbersCounter: this.state.numbersCounter += 1});
        if(this.state.numbersCounter === 15) {
          clearInterval(timer);
          this.setState({animationType: "numbers"})
          this.animationNumbers();
        }
      }, 1000)
  }
  


  animationSwitch(){
    if(this.animationType === "bars") {
      this.setState({animationType: "numbers"})
    } else {
      this.setState({animationType: "bars"})
    }
  }

  barChange(bar, change) {
    let barsAmount = 0;
    
    for(barsAmount; barsAmount <= 4; barsAmount++){
      let randomNumber = (Math.floor(Math.random() * 650) + 1);
      document.getElementById("bar" + barsAmount).style.height = randomNumber + "px";
    }
  }

  render() {

    /*
    <div className="laurels">
            
            <div className="laurel1">
              <img className="wings" src="assets/Laurels/laurel1/laurelWingL1.png"/>
              <img className="base" src="assets/Laurels/laurel1/laurelBase1.png"/>
            </div>

            <div className="laurel0">
              <img className="wings" src="assets/Laurels/laurel0/laurelWingL0.png"/>
              <img className="base" src="assets/Laurels/laurel0/laurelBase0.png"/>
              <img className="wings" src="assets/Laurels/laurel0/laurelWingR0.png"/>
            </div>

          </div>
    */

    let amount = 1;

    for(amount; amount <= 500; amount++){
      let randomNumber = (Math.floor(Math.random() * 25000) + 5)
      this.numbers.push( randomNumber );
    }

    const numberSheet = this.numbers.map((number, key)=> 
      <div className="number" key={key}>{number}</div>
    );

    return (
      <div id="portal" className="videoPage">
        
        {this.state.animationType === "numbers"?
          <div className="numberSheet">
            {numberSheet}
          </div>
          :
          null
        }

        {this.state.animationType === "bars"?
          <div className="barsSheet">
            <div className="backBar">
              <p>0</p>
              <p>25,000</p>
              <p>50,000</p>
              <p>75,000</p>
              <p>90,000</p>
              <p>100,000</p>
            </div>
            <div className="rotate90 backBar">
              <p>0</p>
              <p>50,000</p>
              <p>25,000</p>
              <p></p>
              <p>-25,000</p>
              <p>-50,000</p>
            </div>
            <div className="bar" id="bar0"></div>
            <div className="bar" id="bar1"></div>
            <div className="bar" id="bar2"></div>
            <div className="bar" id="bar3"></div>
            <div className="bar" id="bar4"></div>
          </div>
          :
          null
        }

      </div>
    );
  }
}

export default DemonCoin;
